<template>
  <b-modal
    v-model="isVisible"
    :title="$t('gdpr.title')"
    :ok-title="$t('gdpr.accept')"
    :cancel-disabled="true"
    :hide-header-close="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :ok-only="true"
    :ok-disabled="!accepted"
    size="lg"
    :centered="true"
    @ok="acceptGDPR"
  >
    <div class="d-flex">
      <b-form-checkbox
        v-model="accepted"
        class="mb-1 ml-50"
      />
      <div v-html="gdprText" />
    </div>
  </b-modal>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';

export default {
  name: 'GDPRModal',
  components: {
  },
  data() {
    return {
      isVisible: false,
      accepted: false,
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    GDPRAccepted() {
      return this.$store.state.GDPRAccepted || (this.$store.getters.loggedMember && this.$store.getters.loggedMember.isGDPRAccepted);
    },
    termsNectiosURL() {
      return (
        `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
          '{subdomain}',
          'app',
        ).replace(
          '{slug}',
          'my',
        )}/terms_conditions?locale=${this.currentLocale}`
      );
    },
    privacyNectiosURL() {
      return (
        `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
          '{subdomain}',
          'app',
        ).replace(
          '{slug}',
          'my',
        )}/privacy_policy?locale=${this.currentLocale}`
      );
    },
    gdprText() {
      if (!this.isCommunity) {
        return this.$t('gdpr.my-nectios-text',
          {
            termsCommunity: `<a href="${this.termsNectiosURL}" target="_blank">${this.selectedTitle('terms_conditions')} </a>`,
            policyCommunity: `<a href="${this.privacyNectiosURL}" target="_blank">${this.selectedTitle('privacy_policy')} </a>`
          });
      }
      return this.$t('gdpr.text',
        {
          termsCommunity: `<a href="${this.termsConditionsURL('terms_conditions')}" target="_blank">${this.selectedTitle('terms_conditions')} </a>`,
          policyCommunity: `<a href="${this.termsConditionsURL('privacy_policy')}" target="_blank">${this.selectedTitle('privacy_policy')} </a>`,
          communityName: `<strong>${translateTranslationTable(this.currentLocale, this.currentCollective?.name)}</strong>`,
          termsNectios: `<a href="${this.termsNectiosURL}" target="_blank">${this.selectedTitle('terms_conditions')} </a>`,
          policyNectios: `<a href="${this.privacyNectiosURL}" target="_blank">${this.selectedTitle('privacy_policy')} </a>`
        });
    },
    isCommunity() {
      // This information will not come out if we are in my-netios
      return this.$route?.params?.communityId || this.$route?.params?.communitySlug;
    },
    user() {
      return this.$store.getters.loggedUser;
    },
  },
  watch: {
    user(value) {
      if (!this.isCommunity && value?.isGDPRAccepted === false) {
        this.isVisible = true;
      } else if (this.GDPRAccepted === false) {
        this.isVisible = true;
      }
    },
  },
  created() {
    if (!this.isCommunity && this.user?.isGDPRAccepted === false) {
      this.isVisible = true;
    } else if (this.GDPRAccepted === false) {
      this.isVisible = true;
    }
  },
  methods: {
    termsConditionsURL(typeText) {
      return (
        `${process.env.VUE_APP_LEGAL_CENTER_URL.replace(
          '{subdomain}',
          this.$store.getters.mainCollective?.subdomain
            || this.$store.getters.currentCollective?.subdomain
            || 'app',
        ).replace(
          '{slug}',
          this.$store.getters.mainCollective?.slug
            || this.$store.getters.currentCollective?.slug,
        )}/${typeText}?locale=${this.currentLocale}`
      );
    },
    selectedTitle(word) {
      return this.$t(`legal.${word}`);
    },
    async acceptGDPR() {
      this.$store.dispatch('createItem', {
        item: { itemType: this.isCommunity ? 'acceptGDPR' : '/users/acceptGDPR' },
        noSet: true,
      });

      await this.$store.dispatch('fetchLoggedUser');

      this.isVisible = false;
    },
  },
};
</script>
